import { LitElement, TemplateResult, html, css, PropertyValueMap } from 'lit';
import { Router } from '@vaadin/router';

import { t } from 'i18next';

class LandingPage extends LitElement {
  static styles = css`
    * {
      box-sizing: border-box;
      font-family: var(--font-primary);
    }

    a {
      color: var(--primary);
    }

    a:hover {
      color: var(--primary-highlight);
    }

    a:visited {
      color: var(--primary);
    }

	button {
	  border: none;
      border-radius: 10px;
	  cursor: pointer;
	  font-weight: 700;
	  padding: 15px 20px;
	}

	.button-primary {
      background-color: var(--primary);
      color: white;
	}

	.button-primary:hover {
      background-color: var(--primary-highlight);
	}

    #chat-ai-dialog {
			align-items: center;
			border: 1px solid gray;
			border-radius: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			font-family: var(--font-primary);
			height: 100%;
			max-height: 400px;
			max-width: 500px;
			padding: 20px;
			width: 100%;
		}

    #chat-ai-dialog-contents {
			align-items: center;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: 10px;
			row-gap: 10px;
			width: 100%;
		}

    #close-enter-email-dialog-text-button {
			align-self: flex-end;
			cursor: pointer;
		}

    #phone-number {
			font-weight: 700;
			font-size: 1.5em;
		    margin-bottom: 10px;
			text-align: center;
		}

	#open-in-whatsapp-link {
		color: var(--primary);
		font-weight: 700;
		font-size: 1.5em;
		text-align: center;
		text-decoration: none;
	}

	#open-in-whatsapp-link:hover {
		color: var(--primary-highlight);
	}

    #top-bar {
      align-items: center;
	  background-color: white;
	  box-shadow: 2px 2px 3px rgba(150, 150, 150, 0.3);
      display: flex;
      flex-direction: row;
      justify-content: center;
	  padding: 5px var(--page-padding);
	  position: fixed;
	  top: 0;
      width: 100%;
	  z-index: 999;
    }

	#top-bar-content {
      align-items: center;
      max-width: 1500px;
      display: flex;
	  flex-direction: row;
 	  justify-content: space-between;
	  padding: 0 var(--page-padding);
      width: 100%;
	}

	#app-name-and-logo-container {
		align-items: center;
		display: flex;
		gap: 5px;
	}

	#app-name {
      color: var(--primary);
      font-family: var(--font-primary);
      font-size: 25px;
    }

    #app-name a {
      text-decoration: none;
    }

	#top-bar-app-logo {
      aspect-ratio: 1 / 1;
      background-size: 32px 32px;
      cursor: pointer;
      height: 32px;
    }

	#hero-wrapper {
		margin-top: 70px;
		background-color: #dcf7fa;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	#hero {
		align-items: center;
		display: flex;
		justify-content: center;
		max-width: 1500px;
		padding: 50px var(--page-padding);
		width: 100%;
	}

	#hero-text {
		max-width: 700px;
	}

	#hero-header {
		font-size: 3.25em;
	}

	#hero-tagline {
		color: #666669;
		font-size: 1.2rem;
		margin-bottom: 1.7rem;
	}

	.divider {
		height: 1px;
		width: 100px;
		border: .5px solid lightgray;
		margin: 40px 0;
	}

	#hero-features {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.hero-feature {
		align-items: center;
		display: flex;
		gap: 8px;
	}

	.hero-feature-icon {
		font-size: 1.75rem;
		margin: 0;
	}

	.hero-feature-text {
		margin: 0;
	}

	#hero-image-container {
		display: block;
		height: 520px;
		width: 550px;
	}

	#hero-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	#overview {
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 30px var(--page-padding);
	}

	#overview-header {
		font-size: 2.25rem;
		margin-bottom: 5px;
	}

	#overview-subtitle {
		color: #666669;
		font-size: 1.15rem;
	}

	#overview-features {
		align-items: center;
		display: flex;
		flex-flow: wrap;
		gap: 25px;
		justify-content: center;
		margin-top: 20px;
	}

	.overview-feature {
		background-color: white;
		box-shadow: 0 8px 36px #3c425414;
		border-radius: 10px;
		padding: 30px 20px;
		width: 315px;
	}

	.overview-feature-icon {
		height: 50px;
		width: 50px;
	}

	.overview-feature-title {
		font-weight: 700;
	}

	.overview-feature-description {
		margin-bottom: 0;
	}

	#pitch {
		display: flex;
		justify-content: center;
		gap: 80px;
		margin-top: 120px;
		width: 100%;
	}

	#pitch-image-container {
		height: 448px;
		width: 470px;
	}

	#pitch-image {
		border-radius: 10px;
		height: 100%;
		object-fit: cover;
		object-position: 40% 50%;
		width: 100%;
	}

	#pitch-text {
		max-width: 600px;
	}

	#pitch-header {
		font-size: 2.75rem;
	}

	#pitch-header-emphasized {
		color: var(--primary);
	}

	#pitch-tagline {
		color: #666669;
		font-size: 1.25rem;
	}

	#feature-chatbot {
		align-items: center;
		display: flex;
		gap: 80px;
		justify-content: center;
		margin-top: 120px;
		width: 100%;
	}

	#feature-chatbot-image-container {
		height: 700px;
		width: auto;
	}

	#feature-chatbot-image {
		height: 100%;
		width: 100%;
	}

	#feature-chatbot-text {
		max-width: 500px;
	}

	#feature-chatbot-description {
		color: #666669;
	}

	#feature-dashboard {
		display: flex;
		margin-top: 70px;
		justify-content: center;
		padding: 30px 30px 60px 400px;
		position: relative;
		width: 100%;
	}

	#feature-dashboard-image-container {
		flex: 1 1 35%;
		height: 360px;
		margin-top: 75px;
		position: relative;
	}

	#feature-dashboard-image {
		aspect-ratio: 932 / 870;
		border-radius: 10px;
		height: 100%;
		position: absolute;
		z-index: 2;
	}

	#feature-dashboard-text-container {
		flex: 1 1 500px;
		position: relative;
	}

	#feature-dashboard-text-content {
		position: absolute;
		padding: 30px 30px 30px 50px;
		width: 90%;
		z-index: 1;
	}

	#feature-dashboard-text {
		width: 100%;
	}

	#feature-dashboard-description {
		color: #666669;
	}

	#feature-dashboard-text-background {
		background-color: #dcf7fa;
		border-radius: 10px;
		padding: 30px 0 30px 60px;
		position: absolute;
		width: calc(100% + 20px + 160px);
		height: 87%;
		z-index: 0;
	}

	#footer {
		background-color: navy;
		border-radius: 15px 15px 0 0;
		margin-top: 80px;
		width: 100%;
	}

	#footer * {
		color: white;
	}

	#footer-top {
		align-items: center;
		border-bottom: 1px solid grey;
		display: flex;
		flex-direction: column;
		padding: 50px var(--page-padding);
		width: 100%;
	}

	#footer-header {
		font-size: 2.5em;
		text-align: center;
		max-width: 920px;
	}

	#footer-tagline {
		color: #aaaaaa;
		margin-bottom: 30px;
		max-width: 800px;
		text-align: center;
	}

	#footer-bottom {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 50px;
		padding: 50px var(--page-padding);
		width: 100%;
	}

	#footer-app-name-and-logo-container {
		align-items: center;
		display: flex;
		gap: 5px;
	}

	#footer-app-name {
      color: var(--primary);
      font-family: var(--font-primary);
      font-size: 25px;
    }

    #footer-app-name a {
      text-decoration: none;
    }

    #footer-app-name a:visited {
		color: white;
	}

	#footer-app-logo {
      aspect-ratio: 1 / 1;
      background-size: 32px 32px;
      cursor: pointer;
      height: 32px;
    }

	#footer-bottom-content {
		border-bottom: 1px solid rgba(150, 150, 150, 0.5);
		display: flex;
		justify-content: center;
		gap: 150px;
		padding-bottom: 50px;
		width: 100%;
	}

	#footer-bottom-content * h1 {
		font-size: 1.5em;
	}

	#footer-bottom-content * a {
		color: #aaaaaa;
		text-decoration: none;
	}

	#footer-company-profile {
		display: flex;
		flex-direction: column;
		gap: 5px;
		max-width: 300px;
	}
	
	#footer-company-profile-description {
		color: #aaaaaa;
		margin: 0;
	}

	#footer-copyright {
		color: #aaaaaa;
	}

	.mobile-divider {
		display: none;
	}

	@media (max-width: 1500px) {
		#feature-dashboard {
			padding-left: 70px;
		}
	}

	@media (max-width: 1000px) {
		.mobile-divider {
			display: inline;
		}

		#top-bar {
		  padding: 5px 20px;
		}

		#top-bar-content {
		  padding: 0;
		}

		#hero {
			flex-direction: column;
			gap: 50px;
			padding: 10px 10px 0 10px;
		}

		#hero-text {
			max-width: 90%;
		}

		#hero-header {
			font-size: 2.25rem;
		}

		#hero-features {
			gap: 20px;
		}

		#hero-image-container {
			width: 100vw;
		}

		#hero-image {
			object-position: 80% 60%;
		}

		#overview {
			padding: 30px 30px;
		}

		#pitch {
			align-items: center;
			flex-direction: column;
			gap: 30px;
			margin-top: 70px;
		}

		#pitch-image-container {
			height: auto;
			width: 100vw;
		}

		#pitch-header {
			font-size: 2rem;
		}

		#pitch-subtitle {
			font-size: 1.1rem;
		}

		#pitch-text {
			padding: 0 25px;
		}

		#feature-chatbot {
			flex-direction: column;
			gap: 20px;
			margin-top: 10px;
		}

		#feature-chatbot-image-container {
		    background-color: #dcf7fa;
			border-radius: 10px;
			height: 550px;
			width: auto;
			padding: 0 10px 50px 10px;
		}

		#feature-chatbot-text {
			padding: 0 25px;
		}

		#feature-dashboard {
			align-items: center;
			display: flex;
			flex-direction: column;
			gap: 20px;
			justify-content: center;
			margin-bottom: 30px;
			margin-top: 50px;
			padding: 0;
			position: relative;
		}

		#feature-dashboard-image-container {
		    background-color: #dcf7fa;
			border-radius: 10px;
			flex: auto;
			height: auto;
			margin-top: 0;
			position: relative;
			padding-bottom: 50px;
			z-index: -1;
			width: 100%;
		}

		#feature-dashboard-image {
			height: initial;
			position: relative;
			width: 100%;
		}

		#feature-dashboard-text-container {
			flex: initial;
		}

		#feature-dashboard-text {
			max-width: 100%;
		}
		
		#feature-dashboard-text-content {
			padding: 0;
			position: relative;
		}

		#feature-dashboard-text-container {
			background-color: transparent;
			max-width: 90%;
			position: relative;
			width: 100%;
			z-index: -1;
		}

		#feature-dashboard-text-content {
			width: 100%;
		}

		#feature-dashboard-text-background {
			display: none;
		}

		#footer-top {
			padding: 30px 30px;
		}

		#footer-bottom {
			padding: 30px 30px;
			gap: 20px;
		}

		#footer-header {
			font-size: 1.75em;
		}

		#footer-bottom-content {
			align-items: flex-start;
			flex-direction: column;
			gap: 30px;
			padding-bottom: 40px;
		}

		#footer-company-profile-description {
			max-width: 200px;
		}

		#footer-copyright {
			margin-top: 20px;
		}
	}
  `;

  protected firstUpdated(
		_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
	): void {
		const topBarAppLogoDiv = this.shadowRoot?.getElementById('top-bar-app-logo');
		if (topBarAppLogoDiv) {
			topBarAppLogoDiv.style.backgroundImage = `url('${this._svgAppLogo()}')`;
      this.requestUpdate();
		}

		const footerAppLogoDiv = this.shadowRoot?.getElementById('footer-app-logo');
		if (footerAppLogoDiv) {
			footerAppLogoDiv.style.backgroundImage = `url('${this._svgAppLogo()}')`;
      this.requestUpdate();
		}
	}

  render(): TemplateResult {
    return html`
      <dialog style="visibility: hidden;" id="chat-ai-dialog">
				<p
					id="close-enter-email-dialog-text-button"
					@click="${this._onCloseDialogTextButtonClick}"
				>
					${t('close').toUpperCase()}
				</p>
				<div id="chat-ai-dialog-contents">
          <p id="call-instructions">
            ${t('chatInstructions')}
          </p>
          <p id="phone-number">${process.env.AI_PHONE_NUMBER}</p>
		  <a id="open-in-whatsapp-link" target="_blank" rel="noopener noreferrer" href="${`https://api.whatsapp.com/send/?phone=${process.env.AI_PHONE_NUMBER?.replace(/[^0-9]/g, '')}&text=Hi!%20I%E2%80%99d%20like%20to%20learn%20more%20about%20your%20services%20and%20make%20a%20reservation.`}">${t('openInWhatsApp')}</a>
				</div>
			</dialog>
      <div id="page-container">
        <div id="page-content">
          <div id="page-content-shrinkwrapped">
            <div id="top-bar">
			  <div id="top-bar-content">
			    <div id="app-name-and-logo-container">
                  <div id="top-bar-app-logo" @click="${this._onTopBarAppLogoClick}"></div>
                  <h1 id="app-name"><a href="/">Alfred</a></h1>
			    </div>
			    <button class="button-primary" @click="${this._onChatAiButtonClick}">
		          ${t('chatWithAlfred')}
			    </button>
			  </div>
            </div>
			<div id="hero-wrapper">
			    <div id="hero">
			    <div id="hero-text">
				  <h1 id="hero-header">${t('header')}</h1>
				  <p id="hero-tagline">${t('heroTagline')}</p>
				  <button class="button-primary" @click="${this._onChatAiButtonClick}">
				    ${t('chatWithAlfred')}
				  </button>
				  <div class="divider"></div>
				  <div id="hero-features">
				    <div class="hero-feature">
					  <p class="hero-feature-icon">★</p>
				      <p class="hero-feature-text">${t('instantResponseTimes')}</p>
					</div>
				    <div class="hero-feature">
					  <p class="hero-feature-icon">★</p>
				      <p class="hero-feature-text">${t('saveOnCustomerServiceResources')}</p>
					</div>
				    <div class="hero-feature">
					  <p class="hero-feature-icon">★</p>
				      <p class="hero-feature-text">${t('keepTrackOfLeads')}</p>
					</div>
				  </div>
			    </div>
			    <div id="hero-image-container">
			      <img id="hero-image" src="${this.jpgHeroImage()}" />
			    </div>
			  </div>
			</div>
			<div id="overview">
		  	  <h1 id="overview-header">${t('overviewHeader')}</h1>
			  <h2 id="overview-subtitle">${t('overviewSubtitle')}</h2>
			  <div id="overview-features">
			    <div class="overview-feature">
					<img class="overview-feature-icon" src="${this.svgIconSpeechBubble()}" />
					<p class="overview-feature-title">${t('realTimeConversationsTitle')}</p>
					<p class="overview-feature-description">${t('realTimeConversationsDescription')}</p>
			    </div>
			    <div class="overview-feature">
					<img class="overview-feature-icon" src="${this.svgIconScaleUp()}" />
					<p class="overview-feature-title">${t('scaleEffortlesslyTitle')}</p>
					<p class="overview-feature-description">${t('scaleEffortlesslyDescription')}</p>
			    </div>
			    <div class="overview-feature">
					<img class="overview-feature-icon" src="${this.svgIconContactBook()}" />
					<p class="overview-feature-title">${t('leadsAndRequestsDashboardTitle')}</p>
					<p class="overview-feature-description">${t('leadsAndRequestsDashboardDescription')}</p>
			    </div>
			  </div>
			  <div id="pitch">
				<div id="pitch-image-container">
					<img id="pitch-image" src="${this.jpgPitchImage()}" />
				</div>
				<div id="pitch-text">
				  <h1 id="pitch-header">${t('pitchHeader')} <span id="pitch-header-emphasized">${t('pitchHeaderEmphasized')}</span></h1>
				  <p id="pitch-tagline">${t('pitchTagline')}</p>
				</div>
			  </div>
			  <div class="divider mobile-divider"></div>
			  <div id="feature-chatbot">
			    <div id="feature-chatbot-text">
				  <h1 id="feature-chatbot-header">${t('featureChatbotHeader')}</h1>
				  <p id="feature-chatbot-description">${t('featureChatbotDescription')}</p>
				</div>
			    <div id="feature-chatbot-image-container">
				  <img id="feature-chatbot-image" src="${this.jpgChatbotScreenshot()}" />
				</div>
			  </div>
			  <div id="feature-dashboard">
			    <div id="feature-dashboard-text-container">
			      <div id="feature-dashboard-text">
			        <div id="feature-dashboard-text-content">
				      <h1 id="feature-dashboard-header">${t('featureDashboardHeader')}</h1>
				      <p id="feature-dashboard-description">${t('featureDashboardDescription')}</p>
				    </div>
                    <div id="feature-dashboard-text-background"></div>
				  </div>
			    </div>
				<div id="feature-dashboard-image-container">
			      <img id="feature-dashboard-image" src="${this.jpgDashboardScreenshot()}" />
				</div>
			  </div>
			</div>
			  <div id="footer">
				<div id="footer-top">
				  <h1 id="footer-header">${t('footerHeader')}</h1>
				  <p id="footer-tagline">${t('footerTagline')}</p>
				  <button class="button-primary" @click="${this._onChatAiButtonClick}">${t('chatWithAlfred')}</button>
				</div>
				<div id="footer-bottom">
					<div id="footer-bottom-content">
						<div id="footer-company-profile">
							<div id="footer-app-name-and-logo-container">
							  <div id="footer-app-logo" @click="${this._onTopBarAppLogoClick}"></div>
							  <h1 id="footer-app-name"><a href="/">Alfred</a></h1>
							</div>
							<p id="footer-company-profile-description">${t('companyProfileDescription')}</p>
						</div>
						<div id="footer-our-company">
							<h1>${t('ourCompany')}</h1>
							<a href="https://averistudios.com">${t('About us')}</a>
						</div>
					</div>
					<p id="footer-copyright">© ${new Date().getFullYear()} Averi Studios Inc. ${t('allRightsReserved')}</p>
				</div>
			  </div>
          </div>
      </div>
    `;
  }

  _onChatAiButtonClick() {
    this._sendClickTrackingData('Call Button');
    const chatAiDialog: HTMLDialogElement | null | undefined = <HTMLDialogElement>this.shadowRoot?.getElementById('chat-ai-dialog');
		if (chatAiDialog) {
			chatAiDialog.style.visibility = 'visible';
			chatAiDialog.showModal();
		}
  }

  _onCloseDialogTextButtonClick() {
    const chatAiDialog: HTMLDialogElement | null | undefined = <HTMLDialogElement>this.shadowRoot?.getElementById('chat-ai-dialog');
		if (chatAiDialog) {
			chatAiDialog.style.visibility = 'hidden';
			chatAiDialog.close();
		}
  }

  _onTopBarAppLogoClick() {
    window.location.href = '/';
  }

  _onViewDemoButtonClick() {
    this._sendClickTrackingData('View Demo Menu Button');

    Router.go({ pathname: '/demo-menu' })
  }

  async _sendClickTrackingData(componentName: string) {
		let response: any;

		try {
			response = await fetch(
				`${process.env.BASEROW_CLICK_TRACKING_TABLE_URL}?user_field_names=true`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Token ${process.env.BASEROW_API_TOKEN}`,
					},
					body: JSON.stringify({
						'Clicked Component': componentName,
						'Date Clicked': new Date().toISOString(),
					}),
				},
			);
		} catch (error) {
			console.error('Failed to send click tracking data due to error:' + error);
		}

		if (response) {
			if (!response.ok) {
				console.error(
					'Failed to send click tracking data due to error response.',
				);
			}
		}
	}

  _introImage() {
    return new URL('../../assets/alfred.jpg', import.meta.url);
  }

  _svgAppLogo(): URL {
		return new URL('../../assets/app_logo.png', import.meta.url);
	}

  _svgInstantEngagement(): URL {
		return new URL('../../assets/instant_engagement.svg', import.meta.url);
	}

  _svgFasterOperations(): URL {
		return new URL('../../assets/faster_operations.svg', import.meta.url);
	}

  _svgDataDrivenInsights(): URL {
		return new URL('../../assets/data_driven_insights.svg', import.meta.url);
	}

  private jpgHeroImage(): URL {
		return new URL('../../assets/intro.jpg', import.meta.url);
	}

  private svgIconSpeechBubble(): URL {
		return new URL('../../assets/icon-speech-bubble.svg', import.meta.url);
	}

  private svgIconScaleUp(): URL {
		return new URL('../../assets/icon-scale-up.svg', import.meta.url);
	}

  private svgIconContactBook(): URL {
		return new URL('../../assets/icon-contact-book.svg', import.meta.url);
	}

  private jpgPitchImage(): URL {
    return new URL('../../assets/pitch_image.jpg', import.meta.url);
  }

  private jpgChatbotScreenshot(): URL {
    return new URL('../../assets/chatbot_screenshot.png', import.meta.url);
  }

  private jpgDashboardScreenshot(): URL {
    return new URL('../../assets/dashboard_screenshot.png', import.meta.url);
  }
}

customElements.define('landing-page', LandingPage);
